<template>
  <div class="md-layout admins-wrap">
    <div
      class="
        admins
        md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100
      "
    >
      <div class="admins-navbar">
        <md-tabs md-sync-route>
          <md-tab
            id="tab-promotions-list"
            md-label="Список чатов"
            to="/chats/list"
          />
        </md-tabs>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.admins-wrap {
  position: relative;
  z-index: 2;
}

.admins-navbar {
  margin-bottom: 20px;

  &::v-deep {
    .md-tabs-navigation {
      background-color: #58b05c !important;
      padding: 20px;
    }
  }
}
</style>
